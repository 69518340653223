import CircularButton, { CircularButtonProps } from "../CircularButton";

export type CircularRectTextButtonProps = Omit<CircularButtonProps, "radius">;

/**
 * Circular rectangle text button based on `CircularButton`
 * NOTE:
 * - Please prefer to style with `sx` rather than `style`
 */
const CircularRectTextButton = ({
    ref,
    sx,
    ...circularButtonProps
}: CircularRectTextButtonProps & {
    ref?: React.RefObject<unknown>;
}) => {
    return (
        <CircularButton
            ref={ref}
            sx={[
                {
                    fontWeight: "600",
                    border: "1px solid #00000000",
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            aria-label={undefined}
            title={undefined}
            {...circularButtonProps}
        />
    );
};

CircularRectTextButton.displayName = "CircularRectTextButton";
export default CircularRectTextButton;

"use client";

import { mergeRefs } from "@/utils/mergeRefs";
import { themeColors } from "@/utils/themeColors";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";
import React, { useState } from "react";
import { useMeasure } from "react-use";
import ConditionalTooltip from "../ConditionalTooltip";
import LoadingAnimation, { CenteredSpinnerProps } from "../LoadingAnimation";
import { ConditionalTooltipProps } from "../ConditionalTooltip/ConditionalTooltip";

type ReactButtonHTMLAttributes = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children">;

export type CircularButtonProps = {
    sx?: SxProps;
    radius?: string;
    tooltip?: string;
    TooltipProps?: Omit<ConditionalTooltipProps, "children">;
    children?: React.ReactNode | ((props: { isLoading: boolean }) => React.ReactNode);
    borderRadius?: string;
    component?: React.ElementType;
} & { LoadingProps?: CenteredSpinnerProps } & ReactButtonHTMLAttributes;

/**
 * Our new CircularButton component with simpler props and easily customized through `sx` prop.
 * This component has automatic accessibility (color change on hovered and color change on clicked).
 * It also inherits all button tag prop that you could pass
 * ---
 * NOTE: (so we can prevent to over-extend this):
 * - Please prefer to style with `sx` rather than `style`
 * - if you want to disable the default hover effect, pass `"&:hover": {}` to `sx` prop
 */
const CircularButton = ({
    ref,
    sx,
    tooltip,
    TooltipProps,
    children,
    radius,
    component,
    onClick,
    LoadingProps,
    borderRadius = "99rem",
    ...htmlButtonAttr
}: CircularButtonProps & {
    ref?: React.RefObject<unknown>;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [btnRef, { width, height }] = useMeasure();

    const getChildren = () => {
        if (typeof children === "function") {
            return children({ isLoading: false });
        }
        return children;
    };

    return (
        <ConditionalTooltip tooltip={tooltip} {...TooltipProps}>
            <Box
                ref={mergeRefs([ref, btnRef])}
                component={component || "button"}
                aria-label="Knowt button"
                onClick={async e => {
                    if (isLoading) return;
                    setIsLoading(true);
                    try {
                        if (onClick?.constructor?.name === "Function") {
                            onClick(e);
                        } else if (onClick?.constructor?.name === "AsyncFunction") {
                            await onClick(e);
                        }
                    } finally {
                        setIsLoading(false);
                    }
                }}
                sx={[
                    {
                        // accessibility on hover
                        boxSizing: "border-box",

                        outlineOffset: "-1px",
                        "&:hover": !htmlButtonAttr.disabled
                            ? { outline: `1px solid ${themeColors.neutralBlack}` }
                            : null,
                        "&:active": !htmlButtonAttr.disabled
                            ? { outline: `1px solid ${themeColors.neutralBlack}` }
                            : null,
                        // override the default button style
                        cursor: htmlButtonAttr.disabled ? "default" : "pointer",
                        backgroundColor: themeColors.neutralWhite,
                        borderRadius,
                        border: "none",
                        color: themeColors.neutralBlack,
                        // center the wrapped item
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // customizations...
                        width: radius,
                        height: radius,
                        flexShrink: 0,
                        fontFamily: "var(--knowt-font-name)",
                    },
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
                {...htmlButtonAttr}>
                {isLoading ? (
                    <LoadingAnimation
                        style={{ width, height, ...LoadingProps?.style }}
                        size={LoadingProps?.size || height * 0.65}
                        color={LoadingProps?.color}
                    />
                ) : (
                    getChildren()
                )}
            </Box>
        </ConditionalTooltip>
    );
};

CircularButton.displayName = "CircularButton";
export default CircularButton;

import { themeColors } from "@/utils/themeColors";
import CircularRectTextButton, { CircularRectTextButtonProps } from "./CircularRectTextButton";

export const OutlineTextButton = ({
    ref,
    children,
    sx,
    ...btnProps
}: CircularRectTextButtonProps & {
    ref?: React.RefObject<unknown>;
}) => {
    return (
        <CircularRectTextButton
            ref={ref}
            sx={{
                border: `1px solid ${themeColors.neutral1}`,
                backgroundColor: "transparent",
                color: themeColors.neutralBlack,
                width: "auto",
                padding: "0.5rem 1.5rem",
                height: "3.8rem",
                "&:hover": {
                    backgroundColor: themeColors.neutralBlack,
                    color: themeColors.neutralWhite,
                },
                "&:active": {
                    transform: "scale(0.99)",
                },
                ...sx,
            }}
            {...btnProps}>
            {children}
        </CircularRectTextButton>
    );
};

OutlineTextButton.displayName = "OutlineTextButton";
export default OutlineTextButton;

import Dialog from "@/components/Dialog";
import SlideMenu, { SlideMenuProps } from "@/components/SlideMenu";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { DialogProps } from "@mui/material/Dialog";
import { ResponsiveDialogContextProvider } from "./hooks/useResponsiveDialogContext";
import DialogMainContainer from "./styled/DialogMainContainer";

export type ResponsiveDialogProps = {
    children: React.ReactNode;
    isOpen: boolean;
    disableEscapeKeyDown?: boolean;
    onClose: DialogProps["onClose"];
    /**
     * on which part of the screen the `SlideMenu` (dialog for mobile component) should be shown.
     * @default smDown
     */
    breakpoint?: "smDown" | "mdDown" | "lgDown";
    /** styles applied `Dialog.PaperProps.style` only and take precedence over `style` props */
    DialogStyle?: React.CSSProperties;
    /** styles applied `SlideMenu` container only and take precedence over `style` props */
    SlideMenuStyle?: React.CSSProperties;
    /** styles applied to both `Dialog` and `SlideMenu` */
    style?: React.CSSProperties;
    DialogProps?: Omit<DialogProps, "open" | "onClose" | "PaperProps" | "style" | "children">;
    SlideMenuProps?: Omit<SlideMenuProps, "isOpen" | "style" | "onClickAway" | "children">;
};

/**
 * Combination of both `Dialog` and `SlideMenu` to ease of creating popup component for both desktop and mobile.
 * `Dialog` is used for Desktop.
 * `SlideMenu` is used for Mobile.
 * By default, the main container used within both components is `FlexColumn`.
 */
const ResponsiveDialog = ({
    isOpen,
    onClose,
    disableEscapeKeyDown = false,
    children,
    style,
    DialogStyle,
    SlideMenuStyle,
    DialogProps,
    SlideMenuProps,
    breakpoint = "smDown",
}: ResponsiveDialogProps) => {
    const { smDown, mdDown, lgDown } = useBreakPoints();
    const isMobile = { smDown, mdDown, lgDown }[breakpoint];

    const renderDesktopContent = () => {
        return (
            <Dialog
                onPointerDown={e => {
                    e.stopPropagation();
                    DialogProps?.onPointerDown?.(e);
                }}
                {...DialogProps}
                open={isOpen}
                onClose={onClose}
                disableEscapeKeyDown={disableEscapeKeyDown}
                PaperComponent={DialogMainContainer}
                slotProps={{
                    ...DialogProps?.slotProps,
                    paper: {
                        ...DialogProps?.slotProps?.paper,
                        style: {
                            ...style,
                            ...DialogProps?.slotProps?.paper?.style,
                            ...DialogStyle,
                        },
                    },
                }}>
                {children}
            </Dialog>
        );
    };

    const renderMobileContent = () => {
        return (
            <SlideMenu {...SlideMenuProps} isOpen={isOpen} onClose={onClose}>
                <DialogMainContainer style={{ ...style, ...SlideMenuStyle }}>{children}</DialogMainContainer>
            </SlideMenu>
        );
    };

    return (
        <ResponsiveDialogContextProvider isMobile={isMobile}>
            {isMobile ? renderMobileContent() : renderDesktopContent()}
        </ResponsiveDialogContextProvider>
    );
};

export default ResponsiveDialog;

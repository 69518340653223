/**
 * Merges multiple refs from one source
 * <button ref={mergeRefs([ref1, ref2, ref3])} />
 */
export function mergeRefs<T = any>(
    refs: Array<React.MutableRefObject<T> | React.LegacyRef<T> | undefined | null>
): React.RefCallback<T> {
    return value => {
        for (const ref of refs) {
            if (typeof ref === "function") {
                ref(value);
            } else if (ref != null) {
                (ref as React.MutableRefObject<T | null>).current = value;
            }
        }
    };
}
